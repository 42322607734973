import './main-menu.less';

const MENU_ITEMS_HOVERED_CLASS = 'main-menu__items-container--hovered';
const MENU_ITEM_HOVERED_CLASS = 'main-menu__item--hovered';
const MENU_ITEM_HIDDEN_CLASS = 'main-menu__item--hidden';
const CART_COUNT_SHOWN_CLASS = 'main-menu__cart-count--shown';

/**
 * @param itemList {NodeListOf<HTMLAnchorElement>}
 * @param itemsContainer {HTMLDivElement}
 */
const menuHover = (itemList, itemsContainer) => {
    Array.from(itemList).forEach(item => {
        item.addEventListener('mouseenter', event => {
            itemsContainer.classList.add(MENU_ITEMS_HOVERED_CLASS);
            event.target.classList.add(MENU_ITEM_HOVERED_CLASS);
        });

        item.addEventListener('mouseleave', event => {
            itemsContainer.classList.remove(MENU_ITEMS_HOVERED_CLASS);
            event.target.classList.remove(MENU_ITEM_HOVERED_CLASS);
        });
    });
};

/**
 * @param menu {HTMLDivElement}
 * @param markedMenu {HTMLDivElement}
 * @returns {number}
 */
const getDistance = (menu, markedMenu) => {
    const menuRect = menu.getBoundingClientRect();

    return markedMenu.getBoundingClientRect().left - (menuRect.left + menuRect.width);
};

/**
 * @param markedItem {HTMLAnchorElement}
 * @param menuItems {HTMLDivElement}
 * @param markedItems {HTMLDivElement}
 * @returns {boolean}
 */
const getIsInterSection = (markedItem, menuItems, markedItems) => {
    const distance = getDistance(menuItems, markedItems);
    const markedContent = markedItem.querySelector('.js-mainmenu-item-content');
    const markedContentOffset = markedContent.getBoundingClientRect().left - markedItems.getBoundingClientRect().left;

    return (distance + markedContentOffset) < 0;
};

/**
 * @param infoItem {HTMLAnchorElement}
 * @param cartItem {HTMLAnchorElement}
 * @param menuItems {HTMLDivElement}
 * @param markedItems {HTMLDivElement}
 */
const infoItemHover = (infoItem, cartItem, menuItems, markedItems) => {
    const lastMenuItem = menuItems.children[menuItems.children.length - 1];

    infoItem.addEventListener('mouseenter', () => {
        const isIntersection = getIsInterSection(infoItem, menuItems, markedItems);

        if (isIntersection) {
            lastMenuItem.classList.add(MENU_ITEM_HIDDEN_CLASS);
        }

        cartItem.classList.add(MENU_ITEM_HIDDEN_CLASS);
    });

    infoItem.addEventListener('mouseleave', () => {
        lastMenuItem.classList.remove(MENU_ITEM_HIDDEN_CLASS);
        cartItem.classList.remove(MENU_ITEM_HIDDEN_CLASS);
    });
};

/**
 * @param cartItem {HTMLAnchorElement}
 * @param menuItems {HTMLDivElement}
 * @param markedItems {HTMLDivElement}
 */
const cartItemHover = (cartItem, menuItems, markedItems) => {
    const lastMenuItem = menuItems.children[menuItems.children.length - 1];

    cartItem.addEventListener('mouseenter', () => {
        const isIntersection = getIsInterSection(cartItem, menuItems, markedItems);

        if (isIntersection) {
            lastMenuItem.classList.add(MENU_ITEM_HIDDEN_CLASS);
        }
    });

    cartItem.addEventListener('mouseleave', () => {
        lastMenuItem.classList.remove(MENU_ITEM_HIDDEN_CLASS);
    });
};

/**
 * @param cartItem {HTMLAnchorElement}
 */
const setCartItem = cartItem => {
    const count = cartItem.querySelector('.js-basket-items-count');
    const isCartEmpty = +count.innerText === 0;
    const cartItemIconUse = cartItem.querySelector('svg use');

    /**
     * @param type {string} 'cart' | 'cart-cut'
     * */
    const setCartIcon = type => cartItemIconUse.setAttribute('xlink:href', `#${type}`);

    if (isCartEmpty) {
        count.classList.remove(CART_COUNT_SHOWN_CLASS);
        setCartIcon('cart');

        return;
    }

    setCartIcon('cart-cut');
    count.classList.add(CART_COUNT_SHOWN_CLASS);
};

document.addEventListener('DOMContentLoaded', () => {
    /** @type {HTMLDivElement} */
    const menu = document.querySelector('.js-mainmenu');

    /** @type {HTMLDivElement} */
    const menuItemsContainer = menu.querySelector('.js-mainmenu-items-container');

    /** @type {HTMLDivElement} */
    const menuItems = menu.querySelector('.js-mainmenu-items');

    /** @type {HTMLDivElement} */
    const markedMenuItems = menu.querySelector('.js-mainmenu-marked-items');

    /** @type {NodeListOf<HTMLAnchorElement>} */
    const menuItemsList = menuItemsContainer.querySelectorAll('.js-mainmenu-item');

    /** @type HTMLAnchorElement */
    const cartItem = menu.querySelector('.js-basket-link');

    /** @type HTMLAnchorElement */
    const infoItem = menu.querySelector('.js-info-link');

    menuHover(menuItemsList, menuItemsContainer);

    infoItemHover(infoItem, cartItem, menuItems, markedMenuItems);

    cartItemHover(cartItem, menuItems, markedMenuItems);

    setCartItem(cartItem);

    cartItem.addEventListener('recount', () => setCartItem(cartItem));
});
